div,
span,
section,
main,
header,
footer,
aside,
ul,
li,
ol,
a,
button,
h1,
h2,
h3,
h4,
h5,
h6,
p,
input {
  box-sizing: border-box;
}

body {
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    'Courier New',
    monospace;
}
